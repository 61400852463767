import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { emitter } from "@marvelapp/react-ab-test"
import { AiOutlineLink } from "@react-icons/all-files/ai/AiOutlineLink"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share"
import { PageLoader } from "../common/loader"
import {
  Container,
  HeaderWrapper,
  Flex,
  HeaderTextGroup,
  Subtitle,
} from "../global"

const URL = `${process.env.GATSBY_API_URL}/waitlist`

const Waitlist = ({ location }) => {
  const { clientUrl, title, description } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            clientUrl
            title
            description
          }
        }
      }
    `
  )
  // console.log("waitlist location", location.pathname.split("/waitlist/")[1])
  // const registered =
  //   (typeof window !== "undefined" && window.localStorage.getItem("wait"))
  // console.log("registered", registered)
  const [uuid, _setUuid] = useState(location.pathname.split("/waitlist/")[1])
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState()
  const [copied, setCopied] = useState(false)
  const shareUrl = `${clientUrl}?r=${message?.referral_token}`

  const handleTrack = (btn) => () => emitter.emitWin(`Click Share ${btn}`)

  useEffect(() => {
    const fetchMessage = async () => {
      localStorage.setItem("wait", uuid)
      // const waitlist = typeof window !== "undefined" && window.localStorage.getItem("wait")
      if (!uuid) {
        return setLoading(false)
      }

      try {
        const res = await fetch(URL + `/${uuid}`, {
          headers: {
            "content-type": "application/json",
          },
        })
        const { data } = await res.json()
        setMessage(data)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    fetchMessage()
  }, [])

  if (loading) {
    return <PageLoader />
  }

  return (
    <>
      <HeaderWrapper id="top">
        <Container>
          <Flex>
            <HeaderTextGroup>
              <Subtitle>{title}</Subtitle>
              <h1>
                You're on the
                <br />
                Waitlist!
              </h1>
              <h2>You are currently #{message?.place_in_line || 0}.</h2>
              <p>Share with 5 people you would do a group date with.</p>
            </HeaderTextGroup>
          </Flex>
        </Container>
      </HeaderWrapper>
      <WaitlistWrapper id="share">
        <WaitlistContainer>
          <FooterColumn>
            <span>
              <p>
                So far you {message?.invites > 0 ? "have" : "haven't"} invited{" "}
                <b>{message?.invites > 0 && message.invites}</b> Friends.
              </p>
              <p>
                Every time a friend clicks your link, and then signs up, you get
                even closer to receiving early access.
              </p>
            </span>
            <br />
            <p>
              Move up the list by getting more people to signup with the link
              below!
            </p>
            <p>{shareUrl}</p>
            <br />
            {copied && <span>Copied!</span>}
            <br />
            <ul>
              <li>
                <FacebookShareButton
                  url={shareUrl}
                  quote={description}
                  onClick={handleTrack("Facebook")}
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </li>
              <li>
                <TwitterShareButton
                  url={shareUrl}
                  title={description}
                  onClick={handleTrack("Twitter")}
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </li>
              <li>
                <TelegramShareButton
                  url={shareUrl}
                  title={description}
                  onClick={handleTrack("Telegram")}
                >
                  <TelegramIcon size={32} round />
                </TelegramShareButton>
              </li>
              <li>
                <EmailShareButton
                  url={shareUrl}
                  subject={`Hey! Check Out ${title}`}
                  body={description}
                  onClick={handleTrack("Email")}
                >
                  <EmailIcon size={32} round />
                </EmailShareButton>
              </li>
              <li>
                <CopyToClipboard
                  text={shareUrl}
                  onCopy={() => {
                    handleTrack("Clipboard")()
                    setCopied(true)
                    setTimeout(() => {
                      setCopied(false)
                    }, 800)
                  }}
                  title="Copy to clipboard"
                >
                  <button>
                    <AiOutlineLink size={32} />
                  </button>
                </CopyToClipboard>
              </li>
            </ul>
            <br />
            <br />
          </FooterColumn>
        </WaitlistContainer>
      </WaitlistWrapper>
    </>
  )
}

export default Waitlist

const WaitlistWrapper = styled.footer`
  background-color: white;
  margin: 40px 0 0;
  padding: 0 0 20px;
`

const WaitlistContainer = styled(Container)`
  display: flex;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
  justify-content: center;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`

const FooterColumn = styled.div`
  text-align: center;
  span {
    font-size: 16px;
    font-family: ${(props) => props.theme.font.bold};
    color: ${(props) => props.theme.color.primary};
  }
  ul {
    list-style: none;
    margin: 5px 0;
    padding: 20px;
    color: ${(props) => props.theme.color.black.regular};
    display: flex;
    justify-content: center;
    li {
      margin-bottom: 12px;
      font-family: ${(props) => props.theme.font.normal};
      font-size: 15px;
      padding: 10px;
    }
  }
`
