import React from "react"
import Layout from "../common/layout/layout"
import Header from "../sections/header"
import SEO from "../common/layout/seo"
import Navigation from "../common/navigation/navigation"

const SignupPage = ({ location }) => {
  return (
    <Layout>
      <SEO title="Signup" />
      <Navigation location={location} />
      <Header location={location} />
    </Layout>
  )
}

export default SignupPage
