import React from "react"
import { Router, useParams, Redirect } from "@reach/router"
import { navigate } from "gatsby"

// import Layout from "../components/common/layout/layout"

// import Confirm from "./confirm"
// import Share from "./share"
import Signup from "../components/pages/signup"
import Default from "./index"
import Waitlist from "../components/pages/waitlist"
// import PrivateRoute from "../components/PrivateRoute"

const RedirectRoute = ({ component: Component, ...rest }) => {
  const params = useParams()
  console.log("reachparams", params.id, params.id.length)
  console.log(params.id || params.id.length == 36)

  if (!(params.id && params.id.length == 36)) {
    navigate("/")
    return null
  }
  return <Component {...rest} />
}

const App = () => {
  return (
    <Router basepath="/">
      <RedirectRoute path="/waitlist/:id" component={Waitlist} />
      <Signup path="/signup" />
      <Default path="/" />
      <Redirect noThrow from="*" to="/" />
    </Router>
  )
}

export default App
