import React from "react"

import Layout from "../common/layout/layout"
import SEO from "../common/layout/seo"
import Navigation from "../common/navigation/navigation"
import Waitlist from "../sections/waitlist"
import Footer from "../sections/footer"

// import React from "react"
// import { navigate } from "gatsby"
// import { isLoggedIn } from "../services/auth"
// const PrivateRoute = ({ component: Component, location, ...rest }) => {
//   if (!isLoggedIn() && location.pathname !== `/app/login`) {
//     navigate("/app/login")
//     return null
//   }
//   return <Component {...rest} />
// }

const WaitlistPage = ({ location }) => (
  <Layout>
    <SEO title="Waitlist" />
    <Navigation location={location} />
    <Waitlist location={location} />
    <Footer />
  </Layout>
)

export default WaitlistPage
