import React from "react"
import styled from "styled-components"
import { Container, HeaderWrapper, Flex } from "../global"

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid ${(props) => props.theme.color.secondary};
    border-color: ${(props) => props.theme.color.secondary} transparent
      ${(props) => props.theme.color.secondary} transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const PageLoader = () => (
  <HeaderWrapper id="top">
    <Container>
      <Flex>
        <Loader />
      </Flex>
    </Container>
  </HeaderWrapper>
)
